

































import CoefficientCreationInterface from "@/interfaces/CoefficientCreationInterface";
import CoefficientInterface from "@/interfaces/db/CoefficientInterface";
import SkinToneInterface from "@/interfaces/db/SkinToneInterface";
import configuredAxios from "@/modules/configured-axios";
import Vue from "vue";

export default Vue.extend({
  props: {
    parentVisible: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    valid() {
      return true;
    },
  },
  data: () => ({
    coefficients: [] as CoefficientCreationInterface[],
  }),
  methods: {
    selectImage() {
      (this.$refs.thumbnailSelector as any).$refs.input.click();
    },
    handleDrop(e: DragEvent) {
      if (e.dataTransfer!.files.length) {
        this.thumbnailFile = e.dataTransfer!.files[0];
      }
    },
    validateThumbnail(file: File) {
      if (file.type == "image/png") {
        // We check the file size
        if (file.size < 300000) {
          return true;
        } else {
          throw {
            message:
              "Le fichier est trop volumineux, sa taille doit être inférieure à 300ko",
          };
        }
      } else {
        throw {
          message: "Veuillez utiliser un fichier .png",
        };
      }
    },
    next() {
      const coeffs = [] as CoefficientInterface[];
      for (const coeff of this.coefficients as CoefficientCreationInterface[]) {
        coeffs.push(coeff.coefficient);
      }
      this.$emit("next", coeffs);
    },
    async refreshCoefficients() {
      this.coefficients = [];
      // First we get the list of all the eyeColors, and we format coefficients accordingly
      try {
        // TBD
        const skinTones = (
          await configuredAxios.request({
            url: "/config/skintone",
            method: "GET",
          })
        ).data as SkinToneInterface[];

        for (const color of skinTones) {
          (this.coefficients as CoefficientCreationInterface[]).push({
            name: color.name,
            coefficient: { skintone_id: color.id, multiplier: 5 },
          });
        }
      } catch (error) {
        this.showAlert({
          message: error.response ? error.response.data.message : error.message,
          color: "warning",
          timeout: 3500,
        });
        console.error(error);
      }
    },
  },
  async beforeMount() {
    await this.refreshCoefficients();
  },
  watch: {
    // Find something to watch to reset the thing (e.g : showDialog ?? that should be okay as we need to reset only if we cycle showDialog)
    async parentVisible() {
      if (!this.parentVisible) {
        await this.refreshCoefficients();
      }
    },
  },
});

import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import i18n from '@/modules/i18n'

Vue.use(Vuetify);

export default new Vuetify({
    lang: {
        t: (key, ...params) => i18n.t(key, params).toString(),
    },
});







































































































































































import CoefficientCreationInterface from "@/interfaces/CoefficientCreationInterface";
import CoefficientInterface from "@/interfaces/db/CoefficientInterface";
import EyeColorInterface from "@/interfaces/db/EyeColorInterface";
import FaceShapeInterface from "@/interfaces/db/FaceShapeInterface";
import FrameToneInterface from "@/interfaces/db/FrameToneInterface";
import HairColorInterface from "@/interfaces/db/HairColorInterface";
import SkinToneInterface from "@/interfaces/db/SkinToneInterface";
import configManager from "@/modules/config-manager";
import configuredAxios from "@/modules/configured-axios";

import rules from "@/modules/rules";
import Vue from "vue";
import EditCoefficientsDialogVue from "./EditCoefficientsDialog.vue";

export default Vue.extend({
  components: {
    EditCoefficientsDialogVue,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    show: {
      type: Boolean,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
  },
  computed: {
    numberOfMissingCoefficients() {
      if (this.object) {
        return this.totalCoefficientNumber - this.object.Coefficients.length;
      } else {
        return 0;
      }
    },
    thumbnailFile: {
      get(): File | null {
        return this.thumbnail as File;
      },
      set(value: File) {
        try {
          if (value) {
            if (this.validateThumbnail(value)) {
              this.thumbnail = value;
              this.thumbnailSrc = URL.createObjectURL(value);
              this.thumbnailChanged = true;
            } else {
              this.thumbnail = null;
              this.thumbnailSrc = require("@/assets/placeholder.png");
            }
          } else {
            this.thumbnail = value;
            this.thumbnailSrc = require("@/assets/placeholder.png");
            this.thumbnailChanged = true;
          }
        } catch (error: any) {
          this.$store.dispatch("showAlert", {
            message: error.message,
            color: "warning",
            timeout: 4000,
          });
        }
      },
    },
    showDialog: {
      get(): boolean {
        return this.show;
      },
      set(value: boolean) {
        if (!value) {
          this.object = null;
          this.objectLoaded = false;
          this.objectEdited = false;
          this.totalCoefficientNumber = 0;
          this.editableCoefficients = {
            eyeColor: [],
            hairColor: [],
            skinTone: [],
            // faceShape: [] as CoefficientCreationInterface[]
          };
          this.thumbnailSrc = require("@/assets/placeholder.png");
          (this.$refs.editObjectForm as any).resetValidation();
          this.$emit("close");
          setTimeout(() => {
            this.thumbnailFile = null;
            this.thumbnailChanged = false;
            this.objectLoading = true;
          }, 300);
        }
      },
    },
  },
  data() {
    return {
      showCoefficientDialog: false,
      totalCoefficientNumber: 0,
      editableCoefficients: {
        faceShape: [] as CoefficientCreationInterface[],
      },
      objectLoading: true,
      deleteDialog: false,
      deleteLoading: false,
      updateLoading: false,
      thumbnailSrc: require("@/assets/placeholder.png"),
      thumbnailChanged: false,
      thumbnail: null as File | null,
      object: null as null | FrameToneInterface,
      objectLoaded: false,
      objectEdited: false,
      rules: rules(this),
      valid: false,
    };
  },
  watch: {
    async id() {
      // If ID, then we fetch the details
      if (this.id) {
        // Get total coefficients number we should have configured for this kind of workflow
        this.totalCoefficientNumber = await this.getTotalCoefficientNumber();
        // Fetch object
        await this.getObject();

        // Now, we can create the coefficient list that can be edited
        await this.createEditableCoefficients();
      }
    },
    object: {
      deep: true,
      handler(newValue, oldValue) {
        // If the video changes while being "loaded", it means that the admin has edited it
        // we thus need to set it as "edited to allow the button to be clickable
        if (oldValue && this.objectLoaded) {
          this.objectEdited = true;
        }
      },
    },
  },
  methods: {
    async getTotalCoefficientNumber() {
      // (number of EyeColors + number of HairColor + number of skinTones)
      const faceShapes = (
        await configuredAxios.request({
          url: "/config/faceshape",
          method: "GET",
        })
      ).data as FaceShapeInterface[];

      return faceShapes.length;
    },
    async deleteObject(id: number) {
      this.deleteLoading = true;
      await configuredAxios.request({
        url: `config/workflow/${this.url}/${id}`,
        method: "DELETE",
      });
      this.deleteLoading = false;
      this.showDialog = false;
    },
    async updateObject() {
      try {
        this.updateLoading = true;
        if (this.object) {
          if (this.thumbnailChanged) {
            // If the user has deleted the thumbnail, we send the appropriate request (an empty thumbnail route)
            if (!this.thumbnailFile) {
              await configuredAxios.request({
                url: `config/workflow/${this.url}/${this.object.id}/media`,
                method: "PUT",
              });
            } else {
              // We create the formdata
              const formData = new FormData();
              formData.append("media", this.thumbnailFile);

              const media = (
                await configuredAxios.request({
                  url: `media`,
                  data: formData,
                  method: "POST",
                })
              ).data;
              // Once we got the media, we can assign the thumbnail to the video
              const assignedThumbnail = await configuredAxios.request({
                url: `config/workflow/${this.url}/${this.object.id}/media/${media.id}`,
                method: "PUT",
              });
            }
          }

          await configuredAxios.request({
            url: `config/workflow/${this.url}/${this.object.id}`,
            method: "PUT",
            data: {
              name: this.object.name,
              description: this.object.description,
            },
          });
        }
        this.updateLoading = false;
        this.$store.dispatch("showAlert", {
          message: "L'objet a été mis à jour",
          color: "success",
          timeout: 4000,
        });
        this.$emit("updated");
        this.objectEdited = false;
        this.thumbnailChanged = false;
      } catch (error) {
        console.error(error);
      }
    },
    selectImage() {
      (this.$refs.thumbnailSelector as any).$refs.input.click();
    },
    handleDrop(e: DragEvent) {
      if (e.dataTransfer!.files.length) {
        this.thumbnailFile = e.dataTransfer!.files[0];
      }
    },
    validateThumbnail(file: File) {
      if (file.type == "image/png" || file.type == "image/jpeg") {
        // We check the file size
        if (file.size < 1048000) {
          return true;
        } else {
          throw {
            message:
              "Le fichier est trop volumineux, sa taille doit être inférieure à 1Mo",
          };
        }
      } else {
        throw {
          message: "Veuillez utiliser un fichier .jpg ou .png",
        };
      }
    },
    async getObject() {
      try {
        this.objectLoaded = false;
        const request = await configuredAxios.request({
          url: `config/workflow/${this.url}/${this.id}`,
          method: "GET",
        });
        const object = request.data as FrameToneInterface;
        this.object = object;
        this.objectEdited = false;
        this.objectLoaded = true;
        // If we're here, we can already show the video information
        this.objectLoading = false;
        // If there is a thumbnail, we get the image
        if (this.object.Media) {
          try {
            this.thumbnailSrc = `${configManager.getConfigAsString(
              "axios.apiHost"
            )}/images/${this.object.Media.filename}`;
          } catch (error) {
            console.log(error);
            // Show alert but don't close
          }
        }
      } catch (error) {
        // Show alert and close
      }
    },
    async createEditableCoefficients() {
      this.editableCoefficients = {
        faceShape: [] as CoefficientCreationInterface[],
      };
      // First, we need to sort the coefficients by type, then get the object corresponding and create the object
      // associated with it (name and coefficient wise)
      const faceShapeCoefficients = (
        this.object.Coefficients as CoefficientInterface[]
      ).filter((coeff) => {
        return coeff.faceshape_id;
      });

      // Then, we get the related names and push everything
      for (const coeff of faceShapeCoefficients as CoefficientInterface[]) {
        const relatedObject = (
          await configuredAxios.request({
            url: `config/faceshape/${coeff.faceshape_id}`,
          })
        ).data as FaceShapeInterface;
        (
          this.editableCoefficients.faceShape as CoefficientCreationInterface[]
        ).push({ name: relatedObject.name, coefficient: coeff });
      }
    },
    async createMissingCoefficients() {
      // We create an empty array
      const missingCoefficients = [] as CoefficientInterface[];

      // We need to get all the objects we need to determine the missing coefficients
      const faceShapes = (
        await configuredAxios.request({
          url: "/config/faceshape",
          method: "GET",
        })
      ).data as EyeColorInterface[];
      // Then, for each of the object we search the related coefficient inside the existing object
      for (const object of faceShapes) {
        const coefficientFound = (
          this.object.Coefficients as CoefficientInterface[]
        ).find((coeff) => {
          return coeff.faceshape_id == object.id;
        });
        // if we haven't found it, we need to buffer it inside the appropriate array
        if (!coefficientFound) {
          missingCoefficients.push({
            faceshape_id: object.id,
            multiplier: 5,
          });
        }
      }

      // Now, we create them
      for (const coefficient of missingCoefficients) {
        await configuredAxios.request({
          url: `config/workflow/frameshape/${this.object.id}/coefficient`,
          method: "POST",
          data: coefficient,
        });
      }
      // Once it's created, we just need to go through the object refresh and calculations once more
      await this.getObject();
      // Now, we can create the coefficient list that can be edited
      await this.createEditableCoefficients();
    },
    async afterCloseDialog() {
      this.showCoefficientDialog = false;
      await this.getObject();
      await this.createEditableCoefficients();
    },
  },
});

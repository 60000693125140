

























































































































































import configuredAxios from "@/modules/configured-axios";
import Vue from "vue";

export default Vue.extend({
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    media: {
      type: Boolean,
      required: true,
    },
    showExtendedMedia: {
      type: Boolean,
      default: false,
    },
    showDescription: {
      type: Boolean,
      required: true,
    },
    showRef: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    valid() {
      return (
        !!this.name &&
        (this.media ? !!this.thumbnail : true) &&
        (this.showDescription ? !!this.description : true) &&
        (this.showRef ? !!this.ref : true) &&
        (this.showExtendedMedia ? !!this.maleFile && !!this.femaleFile : true)
      );
    },
    thumbnailFile: {
      get(): File | null {
        return this.thumbnail as File;
      },
      set(value: File) {
        try {
          if (value) {
            if (this.validateThumbnail(value)) {
              this.thumbnail = value;
              this.thumbnailSrc = URL.createObjectURL(value);
              this.thumbnailChanged = true;
            } else {
              this.thumbnail = null;
              this.thumbnailSrc = require("@/assets/placeholder.png");
            }
          } else {
            this.thumbnail = value;
            this.thumbnailSrc = require("@/assets/placeholder.png");
            this.thumbnailChanged = true;
          }
        } catch (error: any) {
          this.$store.dispatch("showAlert", {
            message: error.message,
            color: "warning",
            timeout: 4000,
          });
        }
      },
    },
    maleFile: {
      get(): File | null {
        return this.male as File;
      },
      set(value: File) {
        try {
          if (value) {
            if (this.validateThumbnail(value)) {
              this.male = value;
              this.maleSrc = URL.createObjectURL(value);
              this.maleChanged = true;
            } else {
              this.male = null;
              this.maleSrc = require("@/assets/placeholder.png");
            }
          } else {
            this.male = value;
            this.maleSrc = require("@/assets/placeholder.png");
            this.maleChanged = true;
          }
        } catch (error: any) {
          this.$store.dispatch("showAlert", {
            message: error.message,
            color: "warning",
            timeout: 4000,
          });
        }
      },
    },
    femaleFile: {
      get(): File | null {
        return this.female as File;
      },
      set(value: File) {
        try {
          if (value) {
            if (this.validateThumbnail(value)) {
              this.female = value;
              this.femaleSrc = URL.createObjectURL(value);
              this.femaleChanged = true;
            } else {
              this.female = null;
              this.femaleSrc = require("@/assets/placeholder.png");
            }
          } else {
            this.female = value;
            this.femaleSrc = require("@/assets/placeholder.png");
            this.femaleChanged = true;
          }
        } catch (error: any) {
          this.$store.dispatch("showAlert", {
            message: error.message,
            color: "warning",
            timeout: 4000,
          });
        }
      },
    },
    showDialog: {
      get(): boolean {
        return this.show;
      },
      set(value: boolean) {
        if (!value) {
          this.name = "";
          this.description = "";
          this.ref = "";
          this.thumbnailSrc = require("@/assets/placeholder.png");
          this.maleSrc = require("@/assets/placeholder.png");
          this.femaleSrc = require("@/assets/placeholder.png");
          this.$emit("close");
          setTimeout(() => {
            this.thumbnailFile = null;
            this.thumbnailChanged = false;
            this.maleFile = null;
            this.maleChanged = false;
            this.femaleFile = null;
            this.femaleChanged = false;
            this.createLoading = false;
          }, 300);
        }
      },
    },
    parsedType: {
      get(): string {
        if (this.categoryKey) {
          switch (this.categoryKey) {
            case "move":
              return "Bouger";
            case "health":
              return "Santé";
            case "blog":
              return "Blog";
            default:
              return "";
          }
        } else {
          return "";
        }
      },
    },
  },
  data() {
    return {
      createLoading: false,
      thumbnailSrc: require("@/assets/placeholder.png"),
      thumbnailChanged: false,
      thumbnail: null as File | null,
      maleSrc: require("@/assets/placeholder.png"),
      maleChanged: false,
      male: null as File,
      femaleSrc: require("@/assets/placeholder.png"),
      femaleChanged: false,
      female: null as File,
      name: "",
      description: "",
      ref: "",
    };
  },
  methods: {
    async createObject() {
      try {
        this.createLoading = true;
        const optionalObject = {} as Record<string, any>;
        if (this.showDescription) {
          optionalObject.description = this.description;
        }
        if (this.showRef) {
          optionalObject.ref = this.ref;
        }
        // Creating the object
        const object = (
          await configuredAxios.request({
            method: "POST",
            url: `config/${this.url}`,
            data: {
              name: this.name,
              ...optionalObject,
            },
          })
        ).data;

        if (this.media) {
          // The user has set a new thumbnail, we need to create the media and assign it to the video
          // We create the formdata
          const formData = new FormData();
          formData.append("media", this.thumbnailFile);

          const media = (
            await configuredAxios.request({
              url: `media`,
              data: formData,
              method: "POST",
            })
          ).data;
          // Once we got the media, we can assign the thumbnail to the video

          const assignedThumbnail = await configuredAxios.request({
            url: `config/${this.url}/${object.id}/media/${media.id}`,
            method: "PUT",
          });
        }
        if (this.male) {
          // The user has set a new thumbnail, we need to create the media and assign it to the video
          // We create the formdata
          const formData = new FormData();
          formData.append("media", this.maleFile);

          const media = (
            await configuredAxios.request({
              url: `media`,
              data: formData,
              method: "POST",
            })
          ).data;
          // Once we got the media, we can assign the thumbnail to the video

          const assignedThumbnail = await configuredAxios.request({
            url: `config/${this.url}/${object.id}/malemedia/${media.id}`,
            method: "PUT",
          });
        }
        if (this.female) {
          // The user has set a new thumbnail, we need to create the media and assign it to the video
          // We create the formdata
          const formData = new FormData();
          formData.append("media", this.femaleFile);

          const media = (
            await configuredAxios.request({
              url: `media`,
              data: formData,
              method: "POST",
            })
          ).data;
          // Once we got the media, we can assign the thumbnail to the video

          const assignedThumbnail = await configuredAxios.request({
            url: `config/${this.url}/${object.id}/femalemedia/${media.id}`,
            method: "PUT",
          });
        }

        this.createLoading = false;
        this.$store.dispatch("showAlert", {
          message: "L'objet a été créé",
          color: "success",
          timeout: 4000,
        });
        this.$emit("updated");
        this.name = "";
        this.ref = "";
        this.description = "";
        this.thumbnailChanged = false;
        this.showDialog = false;
      } catch (error) {
        this.$store.dispatch("showAlert", {
          message: error.response ? error.response.data.message : error.message,
          color: "warning",
          timeout: 3500,
        });
        console.error(error);
        this.$emit("updated");
        this.showDialog = false;
      }
    },
    selectImage(ref: string) {
      (this.$refs[ref] as any).$refs.input.click();
    },
    handleDrop(model: string, e: DragEvent) {
      if (e.dataTransfer!.files.length) {
        this[model] = e.dataTransfer!.files[0];
      }
    },
    validateThumbnail(file: File) {
      if (
        file.type == "image/png" ||
        file.type == "image/jpeg" ||
        file.type == "image/svg+xml"
      ) {
        // We check the file size
        if (file.size < 600000) {
          return true;
        } else {
          throw {
            message:
              "Le fichier est trop volumineux, sa taille doit être inférieure à 600ko",
          };
        }
      } else {
        throw {
          message: "Veuillez utiliser un fichier .png, svg ou jpg",
        };
      }
    },
  },
});

import en from "./locales/en"
import fr from "./locales/fr"

import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

const messages = {
    en,
    fr
}

export default new VueI18n(
    {
        messages,
        locale: navigator.language,
        fallbackLocale: "en",
        silentFallbackWarn: true
    }
)
import store from "@/store"
import jwt from "jsonwebtoken"
import api from "../configured-axios";
import request from "../request";

export default {
    parseLocalStorage: (): Record<string, any> | null => {
        const adminInfos = localStorage.getItem('admin-local-infos');
        if (adminInfos) {
            return JSON.parse(adminInfos);
        }
        else {
            return null;
        }
    },
    saveLocalStorage: (adminInfos: Record<string, unknown>): void => {
        localStorage.setItem('admin-local-infos', JSON.stringify(adminInfos));
    },
    // tokenStillValid: (token: string): number | boolean => {
    //     // We decode the string,
    //     const decodedToken: Record<string, any> | null = jwt.decode(token) as Record<string, any> | null;

    //     if (decodedToken) {
    //         if (Math.floor(decodedToken.exp) * 1000 < Date.now()) {
    //             return false;
    //         }
    //         else {
    //             return decodedToken.exp;
    //         }
    //     }
    //     else {
    //         return false;
    //     }
    // },
    login: (loginObject: { userId: number, uuid: string, token: string, expires: number, superAdmin: boolean }): void => {
        store.commit('ADMIN', { ...loginObject, isLogged: true });
        localStorage.setItem('admin-local-infos', JSON.stringify(store.state.admin))
        request.initialize();
        api.authenticate(store.state.admin.token)
    },
    logout: (): void => {
        localStorage.removeItem('admin-local-infos');
        store.commit('ADMIN', {
            isLogged: false,
            token: "",
            expires: 0,
            userId: 0,
        })
    },
    storeLogin: (token: string, userId: number, uuid: string, superAdmin = false): void => {
        // We decode the string,
        const decodedToken: Record<string, any> | null = jwt.decode(token) as Record<string, any> | null;

        if (decodedToken) {
            // We extract the expiration date
            const expires = decodedToken!.exp
            // We set the ADMIN in store
            store.commit('ADMIN', {
                isLogged: true,
                token,
                expires,
                superAdmin,
                uuid,
                userId
            })
        }
    }
}
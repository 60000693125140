




















































import configuredAxios from "@/modules/configured-axios";
import Vue from "vue";
import dateAndTime from "date-and-time";
import { mapActions } from "vuex";

export default Vue.extend({
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    showDialog: {
      get(): boolean {
        return this.show;
      },
      set(value: boolean) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },
  data: () => ({
    startDate: dateAndTime
      .addMonths(new Date(), -1)
      .toISOString()
      .split("T")[0],
    showPickers: false,
    endDate: new Date().toISOString().split("T")[0],
    loading: false,
  }),
  methods: {
    ...mapActions["showAlert"],
    async downloadCsv() {
      this.loading = true;
      try {
        // correct endDate
        const correctEndDate = dateAndTime
          .addDays(new Date(this.endDate), 1)
          .toISOString()
          .split("T")[0];
        const response = await configuredAxios.request({
          url: `summary/csv?start=${this.startDate}&end=${this.correctEndDate}`,
          responseType: "blob",
        });
        const url = URL.createObjectURL(response.data);

        // We create an A element to download
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.style.display = "none";
        a.href = url;
        a.download = `export-bilans-${this.startDate}-${this.endDate}.csv`;
        a.click();
        window.URL.revokeObjectURL(url);
      } catch (error) {
        this.showAlert({
          message: error.response ? error.response.data.message : error.message,
          color: "warning",
          timeout: 3500,
        });
        console.error(error);
      }

      this.loading = false;
    },
  },
});

import { render, staticRenderFns } from "./Step3.vue?vue&type=template&id=6d7fa72a&scoped=true&"
import script from "./Step3.vue?vue&type=script&lang=ts&"
export * from "./Step3.vue?vue&type=script&lang=ts&"
import style0 from "./Step3.vue?vue&type=style&index=0&id=6d7fa72a&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d7fa72a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSlider } from 'vuetify/lib/components/VSlider';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
installComponents(component, {VBtn,VCol,VContainer,VIcon,VRow,VSlider,VSubheader})

















































import Vue from "vue";

export default Vue.extend({
  props: {
    parentVisible: {
      type: Boolean,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    valid() {
      return true;
    },
  },
  data: () => ({}),
  methods: {
    createObject() {
      this.$emit("create");
    },
    cancel() {
      this.$emit("cancel");
    },
  },
});

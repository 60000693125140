import Vue from 'vue'
import VueRouter, { RouteConfig, NavigationGuard, NavigationGuardNext, Route } from 'vue-router'
import Home from '../views/Home.vue'
import store from "@/store";
import auth from "@/modules/auth";
import request from '@/modules/request';

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/admins',
    name: 'Admins',
    component: () => import(/* webpackChunkName: "admins" */ '../views/Admins.vue')
  },
  {
    path: '/store',
    name: 'Store',
    component: () => import(/* webpackChunkName: "store" */ '../views/Store.vue')
  },
  {
    path: '/frames',
    name: 'Frames',
    component: () => import(/* webpackChunkName: "frames" */ '../views/Frames.vue')
  },
  {
    path: '/frameshapes',
    name: 'Frameshapes',
    component: () => import(/* webpackChunkName: "frameshapes" */ '../views/Frameshapes.vue')
  },
  {
    path: '/frametones',
    name: 'Frametones',
    component: () => import(/* webpackChunkName: "frametones" */ '../views/Frametones.vue')
  },
  {
    path: '/brands',
    name: 'Brands',
    component: () => import(/* webpackChunkName: "brands" */ '../views/Brands.vue')
  },
  {
    path: '/colors',
    name: 'Colors',
    component: () => import(/* webpackChunkName: "colors" */ '../views/Colors.vue')
  },
  {
    path: '/matters',
    name: 'Matters',
    component: () => import(/* webpackChunkName: "matters" */ '../views/Matters.vue')
  },
  {
    path: '/styles',
    name: 'Styles',
    component: () => import(/* webpackChunkName: "styles" */ '../views/Styles.vue')
  },
  {
    path: '/faceshapes',
    name: 'Faceshapes',
    component: () => import(/* webpackChunkName: "faceshapes" */ '../views/Faceshapes.vue')
  },
  {
    path: '/skintones',
    name: 'Skintones',
    component: () => import(/* webpackChunkName: "skintones" */ '../views/Skintones.vue')
  },
  {
    path: '/eyecolors',
    name: 'Eyecolors',
    component: () => import(/* webpackChunkName: "eyecolors" */ '../views/Eyecolors.vue')
  },
  {
    path: '/haircolors',
    name: 'Haircolors',
    component: () => import(/* webpackChunkName: "haircolors" */ '../views/HairColors.vue')
  },
  {
    path: '/globalcoefficients',
    name: 'GlobalCoefficients',
    component: () => import(/* webpackChunkName: "globalcoefficients" */ '../views/GlobalCoefficients.vue')
  },
  {
    path: '/summaries',
    name: 'Summaries',
    component: () => import(/* webpackChunkName: "summaries" */ '../views/Summaries.vue')
  },
  {
    path: '/tos',
    name: 'ToS',
    component: () => import(/* webpackChunkName: "tos" */ '../views/ToS.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// Navigation guards
router.beforeEach(async (to, from, next) => {
  // Before each route, we need to check if our token is still valid
  if (store.state.admin.isLogged) {
    // Do nothing
    if (!request.initialized) {
      request.initialize();
    }
  } else {
    const localAdminInfos = auth.parseLocalStorage()
    //
    if (localAdminInfos) {
      // We need to store login infos and initialize the axios request
      auth.storeLogin(localAdminInfos.token, localAdminInfos.userId, localAdminInfos.uuid, localAdminInfos.superAdmin)
      console.log("initializing axios")
      request.initialize();
    }
  }

  // If we don't go to the login page, we need to check if we're logged, if not, go to login :
  if (to.path !== "/login") {
    if (!store.state.admin.isLogged) {
      next('/login')
    } else {
      next();
    }
  }
  else {
    auth.logout();
    next();
  }
})

function isSuperAdmin(to: Route, from: Route, next: NavigationGuardNext<Vue>) {
  if (store.state.admin.superAdmin) {
    next();
  } else {
    next(false);
  }
}

export default router



















































































import BrandInterface from "@/interfaces/db/BrandInterface";
import ColorInterface from "@/interfaces/db/ColorInterface";
import MatterInterface from "@/interfaces/db/MatterInterface";
import StyleInterface from "@/interfaces/db/StyleInterface";
import configuredAxios from "@/modules/configured-axios";
import Vue from "vue";
import AddMediaGalleryDialogVue from "../AddMediaGalleryDialog.vue";
import AddObjectDialogVue from "../AddObjectDialog.vue";

export default Vue.extend({
  components: {
    AddMediaGalleryDialogVue,
    AddObjectDialogVue,
  },
  props: {
    parentVisible: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    valid() {
      return !!this.name && this.selectedBrand &&!!this.sku;
    },
    thumbnailSrc() {
      if (!this.mediaFiles.length) {
        return require("@/assets/placeholder.png");
      } else {
        return URL.createObjectURL(this.mediaFiles[0] as File);
      }
    },
  },
  data: () => ({
    thumbnailChanged: false,
    showAddMediaDialog: false,
    showAddBrandDialog: false,
    name: "",
    sku: "",
    description: "",
    mediaFiles: [] as File[],
    brands: [] as BrandInterface[],
    colors: [] as ColorInterface[],
    matters: [] as MatterInterface[],
    styles: [] as StyleInterface[],
    selectedBrand: 0,
    selectedColor: 0,
    selectedMatter: 0,
    selectedStyle: 0,
  }),
  methods: {
    selectImage() {
      (this.$refs.thumbnailSelector as any).$refs.input.click();
    },
    handleDrop(e: DragEvent) {
      if (e.dataTransfer!.files.length) {
        this.thumbnailFile = e.dataTransfer!.files[0];
      }
    },
    validateThumbnail(file: File) {
      if (file.type == "image/png" || file.type == "image/jpeg") {
        // We check the file size
        if (file.size < 1048000) {
          return true;
        } else {
          throw {
            message:
              "Le fichier est trop volumineux, sa taille doit être inférieure à 1Mo",
          };
        }
      } else {
        throw {
          message: "Veuillez utiliser un fichier .png",
        };
      }
    },
    next() {
      // We create the object :
      const data = {
        name: this.name,
        sku: this.sku,
        description: this.description,
        medias: this.mediaFiles,
        brand_id: this.selectedBrand,
      };
      this.$emit("next", data);
    },
    performFileManagement(data: File[]) {
      this.mediaFiles = [];
      for (const item of data as File[]) {
        this.mediaFiles.push(item);
      }
      this.showAddMediaDialog = false;
    },
    async afterBrandCreatedObject() {
      const brands = (
        await configuredAxios.request({
          url: "/config/brand",
          method: "GET",
        })
      ).data;
      this.brands = brands;
    },
  },
  watch: {
    // Find something to watch to reset the thing (e.g : showDialog ?? that should be okay as we need to reset only if we cycle showDialog)
    parentVisible() {
      if (!this.parentVisible) {
        this.name = "";
        this.sku = "";
        this.description = "";
        this.mediaFiles = [];
        this.selectedBrand = 0;
      }
    },
  },
  async beforeMount() {
    // we get all the objects we will use inside the selects
    const brands = (
      await configuredAxios.request({
        url: "/config/brand",
        method: "GET",
      })
    ).data;
    this.brands = brands;
    const colors = (
      await configuredAxios.request({
        url: "/config/color",
        method: "GET",
      })
    ).data;
    this.colors = colors;
    const matters = (
      await configuredAxios.request({
        url: "/config/matter",
        method: "GET",
      })
    ).data;
    this.matters = matters;
    const styles = (
      await configuredAxios.request({
        url: "/config/style",
        method: "GET",
      })
    ).data;
    this.styles = styles;
  },
});
















































































































































import BrandInterface from "@/interfaces/db/BrandInterface";
import ColorInterface from "@/interfaces/db/ColorInterface";
import MatterInterface from "@/interfaces/db/MatterInterface";
import StyleInterface from "@/interfaces/db/StyleInterface";
import { Gender } from "@/interfaces/Gender";
import configuredAxios from "@/modules/configured-axios";
import Vue from "vue";
import AddObjectDialogVue from "../AddObjectDialog.vue";

export default Vue.extend({
  components: {
    AddObjectDialogVue,
  },
  props: {
    parentVisible: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    valid() {
      return (
        this.selectedColor &&
        this.selectedMatter &&
        this.selectedStyle &&
        this.sizeBridge &&
        this.sizeTemple &&
        this.sizeLens
      );
    },
    thumbnailSrc() {
      if (!this.mediaFiles.length) {
        return require("@/assets/placeholder.png");
      } else {
        return URL.createObjectURL(this.mediaFiles[0] as File);
      }
    },
  },
  data: () => ({
    genders: [
      {
        text: "Homme",
        value: Gender.MALE,
      },
      {
        text: "Femme",
        value: Gender.FEMALE,
      },
      {
        text: "Mixte",
        value: Gender.OTHER,
      },
    ],
    thumbnailChanged: false,
    showAddMediaDialog: false,
    showAddStyleDialog: false,
    showAddColorDialog: false,
    showAddMatterDialog: false,
    gender: Gender.OTHER,
    colors: [] as ColorInterface[],
    matters: [] as MatterInterface[],
    styles: [] as StyleInterface[],
    sizeBridge: 0,
    sizeLens: 0,
    sizeTemple: 0,
    selectedColor: 0,
    selectedMatter: 0,
    selectedStyle: 0,
  }),
  methods: {
    selectImage() {
      (this.$refs.thumbnailSelector as any).$refs.input.click();
    },
    handleDrop(e: DragEvent) {
      if (e.dataTransfer!.files.length) {
        this.thumbnailFile = e.dataTransfer!.files[0];
      }
    },
    validateThumbnail(file: File) {
      if (file.type == "image/png") {
        // We check the file size
        if (file.size < 300000) {
          return true;
        } else {
          throw {
            message:
              "Le fichier est trop volumineux, sa taille doit être inférieure à 300ko",
          };
        }
      } else {
        throw {
          message: "Veuillez utiliser un fichier .png",
        };
      }
    },
    next() {
      // We create the object :
      const data = {
        sizeBridge: this.sizeBridge,
        sizeLens: this.sizeLens,
        sizeTemple: this.sizeTemple,
        selectedColor: this.selectedColor,
        selectedMatter: this.selectedMatter,
        selectedStyle: this.selectedStyle,
        gender: this.gender,
      };
      this.$emit("next", data);
    },
    performFileManagement(data: File[]) {
      this.mediaFiles = [];
      for (const item of data as File[]) {
        this.mediaFiles.push(item);
      }
      this.showAddMediaDialog = false;
    },
    async afterStyleCreatedObject() {
      const styles = (
        await configuredAxios.request({
          url: "/config/style",
          method: "GET",
        })
      ).data;
      this.styles = styles;
    },
    async afterColorCreatedObject() {
      const colors = (
        await configuredAxios.request({
          url: "/config/color",
          method: "GET",
        })
      ).data;
      this.colors = colors;
    },
    async afterMatterCreatedObject() {
      const matters = (
        await configuredAxios.request({
          url: "/config/matter",
          method: "GET",
        })
      ).data;
      this.matters = matters;
    },
  },
  watch: {
    // Find something to watch to reset the thing (e.g : showDialog ?? that should be okay as we need to reset only if we cycle showDialog)
    parentVisible() {
      if (!this.parentVisible) {
        this.sizeBridge = 0;
        this.sizeLens = 0;
        this.sizeTemple = 0;
        this.selectedColor = 0;
        this.selectedMatter = 0;
        this.selectedStyle = 0;
        this.gender = Gender.OTHER;
      }
    },
  },
  async beforeMount() {
    // we get all the objects we will use inside the selects
    const colors = (
      await configuredAxios.request({
        url: "/config/color",
        method: "GET",
      })
    ).data;
    this.colors = colors;
    const matters = (
      await configuredAxios.request({
        url: "/config/matter",
        method: "GET",
      })
    ).data;
    this.matters = matters;
    const styles = (
      await configuredAxios.request({
        url: "/config/style",
        method: "GET",
      })
    ).data;
    this.styles = styles;
  },
});

















































































import CoefficientInterface from "@/interfaces/db/CoefficientInterface";
import { Gender } from "@/interfaces/Gender";
import configuredAxios from "@/modules/configured-axios";
import Vue from "vue";
import { mapActions } from "vuex";
import Step1Vue from "./add-frame/Step1.vue";
import Step2Vue from "./add-frame/Step2.vue";
import Step3Vue from "./add-frame/Step3.vue";
import Step4Vue from "./add-frame/Step4.vue";
import Step5Vue from "./add-frame/Step5.vue";
import Step6Vue from "./add-frame/Step6.vue";
import Step7Vue from "./add-frame/Step7.vue";

export default Vue.extend({
  components: {
    Step1Vue,
    Step2Vue,
    Step3Vue,
    Step4Vue,
    Step5Vue,
    Step6Vue,
    Step7Vue,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    showDialog: {
      get(): boolean {
        return this.show;
      },
      set(value: boolean) {
        if (!value) {
          this.$emit("close");
          this.object.name = "";
          this.object.description = "";
          this.object.gender = Gender.OTHER;
          this.object.sku = "";
          this.object.gallery = [];
          this.object.sizeBridge = 0;
          this.object.sizeTemple = 0;
          this.object.sizeLens = 0;
          this.object.brand_id = 0;
          this.object.color_id = 0;
          this.object.matter_id = 0;
          this.object.style_id = 0;
          this.object.coefficients = [];
          setTimeout(() => {
            // TBD
            this.step = 1;
          }, 300);
        }
      },
    },
  },
  data() {
    return {
      createLoading: false,
      step: 1,
      object: {
        name: "",
        description: "",
        gender: Gender.OTHER,
        sku: "",
        sizeBridge: 0,
        sizeLens: 0,
        sizeTemple: 0,
        gallery: [] as File[],
        brand_id: 0,
        color_id: 0,
        matter_id: 0,
        style_id: 0,
        coefficients: [] as CoefficientInterface[],
      },
    };
  },
  methods: {
    ...mapActions(["showAlert"]),
    completeStep1(data: {
      name: string;
      medias: File[];
      description: string;
      brand_id: number;
      sku: string;
    }) {
      this.object.name = data.name;
      this.object.gallery = data.medias;
      this.object.description = data.description;
      this.object.brand_id = data.brand_id;
      this.object.sku = data.sku;
      this.step = 2;
    },
    completeStep2(data: {
      selectedColor: number;
      selectedMatter: number;
      selectedStyle: number;
      gender: Gender;
      sizeBridge: number;
      sizeTemple: number;
      sizeLens: number;
    }) {
      this.object.color_id = data.selectedColor;
      this.object.matter_id = data.selectedMatter;
      this.object.style_id = data.selectedStyle;
      this.object.gender = data.gender;
      this.object.sizeBridge = data.sizeBridge;
      this.object.sizeTemple = data.sizeTemple;
      this.object.sizeLens = data.sizeLens;
      this.step = 3;
    },
    completeStep3(data: CoefficientInterface[]) {
      // We need to delete all the coefficients that have an eyecolor_id before updating them
      this.object.coefficients = (
        this.object.coefficients as CoefficientInterface[]
      ).filter((coeff) => {
        return !coeff.eyecolor_id;
      });
      for (const coeff of data) {
        this.object.coefficients.push(coeff);
      }
      this.step = 4;
    },
    completeStep4(data: CoefficientInterface[]) {
      // We need to delete all the coefficients that have an eyecolor_id before updating them
      this.object.coefficients = (
        this.object.coefficients as CoefficientInterface[]
      ).filter((coeff) => {
        return !coeff.haircolor_id;
      });
      for (const coeff of data) {
        this.object.coefficients.push(coeff);
      }
      this.step = 5;
    },
    completeStep5(data: CoefficientInterface[]) {
      // We need to delete all the coefficients that have an eyecolor_id before updating them
      this.object.coefficients = (
        this.object.coefficients as CoefficientInterface[]
      ).filter((coeff) => {
        return !coeff.skintone_id;
      });
      for (const coeff of data) {
        this.object.coefficients.push(coeff);
      }
      this.step = 6;
    },
    completeStep6(data: CoefficientInterface[]) {
      // We need to delete all the coefficients that have an eyecolor_id before updating them
      this.object.coefficients = (
        this.object.coefficients as CoefficientInterface[]
      ).filter((coeff) => {
        return !coeff.faceshape_id;
      });
      for (const coeff of data) {
        this.object.coefficients.push(coeff);
      }
      this.step = 7;
    },
    async completeStep7() {
      // TBD
      this.createLoading = true;
      try {
        // Creating the object
        const object = (
          await configuredAxios.request({
            method: "POST",
            url: `config/workflow/frame`,
            data: {
              name: this.object.name,
              sku: this.object.sku,
              gender: this.object.gender,
              description: this.object.description,
              sizeBridge: this.object.sizeBridge,
              sizeLens: this.object.sizeLens,
              sizeTemple: this.object.sizeTemple,
            },
          })
        ).data;

        for (const media of this.object.gallery) {
          // The user has set a new thumbnail, we need to create the media and assign it to the video
          // We create the formdata
          const formData = new FormData();
          formData.append("media", media);
          const mediaObject = (
            await configuredAxios.request({
              url: `media`,
              data: formData,
              method: "POST",
            })
          ).data;
          // Once we got the media, we can assign the thumbnail to the video
          const assignedThumbnail = await configuredAxios.request({
            url: `config/workflow/frame/${object.id}/media/${mediaObject.id}`,
            method: "PUT",
          });
        }

        // We assign the brand, color, matter and style
        await configuredAxios.request({
          url: `config/workflow/frame/${object.id}/brand/${this.object.brand_id}`,
          method: "PUT",
        });
        await configuredAxios.request({
          url: `config/workflow/frame/${object.id}/color/${this.object.color_id}`,
          method: "PUT",
        });
        await configuredAxios.request({
          url: `config/workflow/frame/${object.id}/matter/${this.object.matter_id}`,
          method: "PUT",
        });
        await configuredAxios.request({
          url: `config/workflow/frame/${object.id}/style/${this.object.style_id}`,
          method: "PUT",
        });

        // Now the fun part, we can create every coefficients we previously set :
        for (const coefficient of this.object.coefficients) {
          await configuredAxios.request({
            url: `config/workflow/frame/${object.id}/coefficient`,
            method: "POST",
            data: coefficient,
          });
        }
      } catch (error) {
        this.showAlert({
          message: error.response ? error.response.data.message : error.message,
          color: "warning",
          timeout: 3500,
        });
        console.error(error);
      }
      this.createLoading = false;
      this.showDialog = false;
    },
    cancelEverything() {
      // TBD
      this.showDialog = false;
    },
  },
});
















import { VueEditor } from "vue2-editor";

import Vue from "vue";
import configuredAxios from "@/modules/configured-axios";

export default Vue.extend({
  components: {
    VueEditor
  },
  data() {
    return {
      content: "",
      loading: false,
      color: "secondary"
    };
  },
  async beforeMount() {
    this.content = (
      await configuredAxios.request({
        url: "/config/tos"
      })
    ).data.tos;
  },
  methods: {
    async save() {
      this.loading = true;
      try {
        await configuredAxios.request({
          url: "/config/tos",
          method: "PUT",
          data: {
            tos: this.content
          }
        });
        this.color = "success";
        setTimeout(() => {
          this.color = "secondary";
        }, 1000);
      } catch (error) {
        this.color = "error";
        setTimeout(() => {
          this.color = "secondary";
        }, 2000);
      } finally {
        this.loading = false;
      }
    }
  }
});

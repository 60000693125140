



















































































import Vue from "vue";
import GalleryItem from "@/interfaces/GalleryItem";
import Request from "@/modules/request";
import Media from "@/interfaces/db/MediaInterface";
import configuredAxios from "@/modules/configured-axios";
import FrameInterface from "@/interfaces/db/FrameInterface";
import configManager from "@/modules/config-manager";

export default Vue.extend({
  props: {
    id: {
      type: Number,
      required: true,
    },
    show: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    showDialog: {
      get(): boolean {
        return this.show;
      },
      set(value: boolean) {
        if (!value) {
          this.gallery = [];
          this.galleryLoaded = false;
          this.galleryChanged = false;
          this.$emit("close");
        }
      },
    },
    filteredGallery: {
      get(): GalleryItem[] {
        return this.gallery.filter((value) => {
          return !value.markedForDeletion;
        });
      },
    },
  },
  data: () => ({
    saveLoading: false,
    gallery: [] as GalleryItem[],
    galleryPickingDialogFiles: [] as File[],
    galleryLoaded: false,
    galleryChanged: false,
  }),
  watch: {
    async show() {
      if (this.show && this.id) {
        // Fetch video object
        this.getGallery();
      }
    },
    galleryPickingDialogFiles() {
      // We need to process the files
      console.log("we've dropped some files");
      if (this.galleryLoaded) {
        if (this.galleryPickingDialogFiles.length) {
          // We validate each file
          for (const file of this.galleryPickingDialogFiles) {
            if (this.validateFile(file)) {
              // If file is valid, we create an entry in the array
              this.gallery.push({
                data: URL.createObjectURL(file),
                markedForDeletion: false,
                file,
              });
              this.galleryChanged = true;
            }
          }
          this.galleryPickingDialogFiles = [];
        }
      }
    },
  },
  methods: {
    async updateGallery() {
      try {
        this.saveLoading = true;
        // We need to cycle through the gallery array
        for (const media of this.gallery) {
          // If there is an ID, we check if it's marked for deletion, if so, we delete it
          if (media.id && media.markedForDeletion) {
            await Request.shared.delete(`config/workflow/frame/${this.id}/media/${media.id}`);
          }
          // else if !media.id, it means that we need to create it
          else if (!media.id && media.file) {
            const formData = new FormData();
            formData.append("media", media.file);
            const mediaObject = (
              await configuredAxios.request({
                url: `media`,
                data: formData,
                method: "POST",
              })
            ).data;
            // Once we got the media, we can assign the thumbnail to the video
            const assignedThumbnail = await configuredAxios.request({
              url: `config/workflow/frame/${this.id}/media/${mediaObject.id}`,
              method: "PUT",
            });
          }
        }
        this.galleryChanged = false;
        this.saveLoading = false;
        this.$store.dispatch("showAlert", {
          message: "La galerie a été mise à jour",
          color: "success",
          timeout: 4000,
        });
      } catch (error) {
        console.error(error);
      }
    },
    handleDrop(e: DragEvent, fileKey: string) {
      if (e.dataTransfer?.files.length) {
        for (const file of e.dataTransfer.files) {
          if (this.validateFile(file)) {
            this.gallery.push({
              data: URL.createObjectURL(file),
              markedForDeletion: false,
              file,
            });
            this.galleryChanged = true;
          }
        }
      }
    },
    markForDeletion(media: GalleryItem) {
      media.markedForDeletion = true;
      this.galleryChanged = true;
    },
    validateFile(file: File) {
      if (file.type == "image/png" || file.type == "image/jpeg") {
        // We check the file size
        if (file.size < 1048000) {
          return true;
        } else {
          throw {
            message:
              "Le fichier est trop volumineux, sa taille doit être inférieure à 1Mo",
          };
        }
      } else {
        throw {
          message: "Veuillez utiliser un fichier .jpg ou .png",
        };
      }
    },
    selectImage(ref: string) {
      (
        this.$refs?.[ref as keyof (Vue | Element | (Vue | Element)[])] as any
      ).$refs.input.click();
    },
    async getGallery() {
      try {
        // We get all the gallery items for the coach
        this.galleryLoaded = false;

        // We get the frame we're talking about
        const frame = (
          await configuredAxios.request({
            url: `config/workflow/frame/${this.id}`,
            method: "GET",
          })
        ).data as FrameInterface;

        const gallery = frame.Medias;
        for (const media of gallery) {
          // We get the media
          try {
            this.gallery.push({
              id: media.id,
              data: `${configManager.getConfigAsString(
                "axios.apiHost"
              )}/images/${media.filename}`,
              markedForDeletion: false,
            });
          } catch (error) {
            console.log(error);
            // Show alert but don't close
          }
        }
        this.galleryLoaded = true;
      } catch (error) {
        console.error(error);
      }
    },
  },
});

import en from "vuetify/src/locale/en"

export default {
    $vuetify: {
        ...en,
    },

    placeholder: {
        welcome: "Optique Lafayette - Parcours Visagisme",
        caption: "Utilisez le menu de gauche",
        switchButton: "Basculer en "
    }
}
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"
import store from "@/store"
import configManager from "@/modules/config-manager"
// Errors handling
const errors = {
    default: (error: AxiosError): void => {
        // default error calls showAlert with the status and message of the error
        let color = ""
        if (error.response) {
            if (error.response.status < 500 && error.response.status >= 400) {
                color = "warning"
            } else {
                color = "error"
            }
            store.dispatch('showAlert', { message: `Erreur ${error.response.status} - ${error.response.data.message}`, color, timeout: 4000 })
        }
        else {
            console.error(error);
        }
    }
}
const success = {
    default: (response: AxiosResponse): void => {
        // Define success
        console.log(response)
    }
}

class ConfiguredAxios {
    public request: AxiosInstance
    public authenticate(token: string) {
        this.request.defaults.headers['Authorization'] = `Bearer ${token}`
    }

    public errors = errors
    public success = success

    constructor(config: AxiosRequestConfig) {
        this.request = axios.create(config)
    }

}

// Initializing API Axios instance
export default new ConfiguredAxios({
    baseURL: configManager.getConfigAsString("axios.apiHost"),
    timeout: configManager.getConfigAsNumber("axios.request.timeout")
})
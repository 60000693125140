
























import i18n from "@/modules/i18n";
import Vue from "vue";

export default Vue.extend({
  name: "HelloWorld",
  computed: {
    otherLocale(): string {
      if (i18n.locale.startsWith("en")) {
        return "🇫🇷";
      } else {
        return "🇬🇧";
      }
    },
  },
  data: () => ({}),
  methods: {
    switchLanguage() {
      if (i18n.locale.startsWith("en")) {
        i18n.locale = "fr";
      } else {
        i18n.locale = "en";
      }
    },
  },
});

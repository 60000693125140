import fr from "vuetify/src/locale/fr"

export default {
    $vuetify: {
        ...fr
    },

    placeholder: {
        welcome: "Optique Lafayette - Parcours Visagisme",
        caption: "Utilisez le menu de gauche",
        switchButton: "Basculer en "
    }
}
import { render, staticRenderFns } from "./EditCoefficientsDialog.vue?vue&type=template&id=5a980a8f&scoped=true&"
import script from "./EditCoefficientsDialog.vue?vue&type=script&lang=ts&"
export * from "./EditCoefficientsDialog.vue?vue&type=script&lang=ts&"
import style0 from "./EditCoefficientsDialog.vue?vue&type=style&index=0&id=5a980a8f&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a980a8f",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSlider } from 'vuetify/lib/components/VSlider';
installComponents(component, {VBtn,VCard,VCol,VContainer,VDialog,VRow,VSlider})



































































import Vue from "vue";
import { mapState } from "vuex";
import AddStoreDialog from "@/components/AddStoreDialog.vue";
import Request from "@/modules/request";
import configuredAxios from "@/modules/configured-axios";

export default Vue.extend({
  computed: {
    ...mapState(["admin"]),
  },
  components: {
    AddStoreDialog,
  },
  data: () => ({
    showAddStoreDialog: false,
    showConfirmDialog: false,
    storeToDelete: 0,
    loadingDelete: false,
  }),
  beforeMount() {
    if (!this.$store.state.stores.length) {
      this.$store.dispatch("getStores");
    }
  },
  methods: {
    showConfirmation(id: number) {
      console.log(id)
      this.storeToDelete = id;
      this.showConfirmDialog = true;
    },
    hideConfirmation() {
      this.storeToDelete = 0;
    },
    async confirmDeleteStore() {
      this.loadingDelete = true;
      try {
        console.log(this.storeToDelete)
        await configuredAxios.request({
          method: "DELETE",
          url: `store/${this.storeToDelete}`,
        });
        this.$store.dispatch("getStores");
      } catch (error) {
        console.error(error);
      }
      this.loadingDelete = false;
      this.showConfirmDialog = false;
    },
  },
  watch: {
    showConfirmDialog() {
      if (!this.showConfirmDialog) {
        this.hideConfirmation();
      }
    },
  },
});


































import CSVSummaryExportVue from "@/components/CSVSummaryExport.vue";
import FrameInterface from "@/interfaces/db/FrameInterface";
import configuredAxios from "@/modules/configured-axios";
import Vue from "vue";
import { DataTableHeader } from "vuetify";
import { mapActions } from "vuex";

export default Vue.extend({
  components: {
    CSVSummaryExportVue,
  },
  computed: {},
  data: () => ({
    showCsvDialog: false,
    headers: [
      {
        text: "Id",
        value: "id",
      },
      {
        text: "Montures",
        value: "concatenatedFrames",
      },
      {
        text: "Forme",
        value: "FrameShape.name",
      },
      {
        text: "Couleur",
        value: "FrameTone.name",
      },
      {
        text: "E-mail client",
        value: "Customer.email",
      },
    ] as DataTableHeader[],
    summaries: [],
    loading: false,
  }),
  methods: {
    ...mapActions(["showAlert"]),
    async refreshSummaries() {
      // TBD
      this.loading = true;
      try {
        const summaries = (
          await configuredAxios.request({
            url: "/summary",
            method: "GET",
          })
        ).data;
        this.summaries = summaries;
      } catch (error) {
        this.showAlert({
          message: error.response ? error.response.data.message : error.message,
          color: "warning",
          timeout: 3500,
        });
        console.error(error);
      }
      this.loading = false;
    },
    getConcatenatedFrames(frames: FrameInterface[]) {
      let buffer = "";
      for (const frameIndex in frames) {
        buffer += frames[frameIndex].name;
        if (parseInt(frameIndex) + 1 < frames.length) {
          buffer += " - ";
        }
      }
      return buffer;
    },
  },
  async beforeMount() {
    // TBD
    await this.refreshSummaries();
  },
});























































































































import AddFrameDialogVue from "@/components/AddFrameDialog.vue";
import EditFrameDialogVue from "@/components/EditFrameDialog.vue";
import FrameInterface from "@/interfaces/db/FrameInterface";
import { Gender } from "@/interfaces/Gender";
import configuredAxios from "@/modules/configured-axios";
import Vue from "vue";
import { DataTableHeader } from "vuetify";
import { mapActions } from "vuex";

export default Vue.extend({
  components: {
    AddFrameDialogVue,
    EditFrameDialogVue,
  },
  computed: {
    filteredFrames() {
      let array = this.frames as FrameInterface[];

      if (this.filters.brand) {
        array = array.filter((frame) => {
          return (
            frame.Brand &&
            frame.Brand.name
              .toLowerCase()
              .includes(this.filters.brand.toLowerCase())
          );
        });
      }
      if (this.filters.name) {
        array = array.filter((frame) => {
          return frame.name
            .toLowerCase()
            .includes(this.filters.name.toLowerCase());
        });
      }
      if (this.filters.gender > -1) {
        array = array.filter((frame) => {
          return frame.gender == this.filters.gender;
        });
      }
      if (this.filters.color) {
        array = array.filter((frame) => {
          return (
            frame.Color &&
            (frame.Color.name
              .toLowerCase()
              .includes(this.filters.color.toLowerCase()) ||
              frame.Color.ref
                .toLowerCase()
                .includes(this.filters.color.toLowerCase()))
          );
        });
      }
      if (this.filters.matter) {
        array = array.filter((frame) => {
          return (
            frame.Matter &&
            frame.Matter.name
              .toLowerCase()
              .includes(this.filters.matter.toLowerCase())
          );
        });
      }
      if (this.filters.style) {
        array = array.filter((frame) => {
          return (
            frame.Style &&
            frame.Style.name
              .toLowerCase()
              .includes(this.filters.style.toLowerCase())
          );
        });
      }

      return array;
    },
  },
  data: () => ({
    genders: [
      {
        text: "Homme",
        value: Gender.MALE,
      },
      {
        text: "Femme",
        value: Gender.FEMALE,
      },
      {
        text: "Mixte",
        value: Gender.OTHER,
      },
    ],
    headers: [
      {
        text: "Marque",
        value: "Brand.name",
      },
      {
        text: "Modèle",
        value: "name",
      },
      {
        text: "Genre",
        value: "gender",
      },
      {
        text: "Couleur",
        value: "formattedColor",
      },
      {
        text: "Matière",
        value: "Matter.name",
      },
      {
        text: "Style",
        value: "Style.name",
      },
    ] as DataTableHeader[],
    frames: [] as FrameInterface[],
    filters: {
      brand: "",
      name: "",
      gender: -1 as number | Gender,
      color: "",
      matter: "",
      style: "",
    },
    totalFrames: 0,
    options: {} as Record<string, any>,
    loading: false,
    showAddDialog: false,
    showEditDialog: false,
    showDeleteDialog: false,
    editIndex: 0,
    deleteIndex: 0,
    deleteLoading: false,
  }),
  methods: {
    ...mapActions(["showAlert"]),
    getFormattedGender(gender: Gender) {
      switch (gender) {
        case Gender.MALE:
          return "Homme";
        case Gender.FEMALE:
          return "Femme";
        case Gender.OTHER:
          return "Mixte";
      }
    },
    async refreshFrames() {
      // TBD
      this.loading = true;
      try {
        const frames = (
          await configuredAxios.request({
            url: "/config/workflow/frame",
            method: "GET",
          })
        ).data;
        this.frames = frames;
      } catch (error) {
        this.showAlert({
          message: error.response ? error.response.data.message : error.message,
          color: "warning",
          timeout: 3500,
        });
        console.error(error);
      }
      this.loading = false;
    },
    async afterCreatedObject() {
      this.showAddDialog = false;
      await this.refreshFrames();
    },
    async editObject(id: number) {
      this.editIndex = id;
      this.showEditDialog = true;
    },
    async afterEditedObject() {
      this.showEditDialog = false;
      this.editIndex = 0;
      await this.refreshFrames();
    },
    handleRowClick(data) {
      this.editObject(data.id);
    },
  },
  async beforeMount() {
    // TBD
    await this.refreshFrames();
  },
});

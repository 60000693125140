































































































import CoefficientCreationInterface from "@/interfaces/CoefficientCreationInterface";
import CoefficientInterface from "@/interfaces/db/CoefficientInterface";
import EyeColorInterface from "@/interfaces/db/EyeColorInterface";
import configuredAxios from "@/modules/configured-axios";
import Vue from "vue";

export default Vue.extend({
  props: {
    show: {
      type: Boolean,
      required: true
    },
    editableCoefficients: {
      type: Object,
      required: true
    },
    rootObjectId: {
      type: Number,
      required: true
    },
    type: {
      type: String,
      required: true
    }
  },
  computed: {
    showDialog: {
      get(): boolean {
        return this.show;
      },
      set(value: boolean) {
        if (!value) {
          this.coefficients = {
            eyeColor: [],
            hairColor: [],
            skinTone: [],
            faceShape: [] as CoefficientCreationInterface[]
          };
          this.$emit("close");
        }
      }
    }
  },
  data: () => ({
    coefficients: {
      eyeColor: [] as CoefficientCreationInterface[],
      hairColor: [] as CoefficientCreationInterface[],
      skinTone: [] as CoefficientCreationInterface[],
      faceShape: [] as CoefficientCreationInterface[]
    },
    loading: false
  }),
  methods: {
    async updateCoefficients() {
      this.loading = true;
      this.$emit("loading", true);
      try {
        for (const coeff of this.coefficients
          .eyeColor as CoefficientCreationInterface[]) {
          await configuredAxios.request({
            url: `config/workflow/${this.type}/${this.rootObjectId}/coefficient/${coeff.coefficient.id}`,
            method: "PUT",
            data: {
              multiplier: coeff.coefficient.multiplier
            }
          });
        }
        for (const coeff of this.coefficients
          .hairColor as CoefficientCreationInterface[]) {
          await configuredAxios.request({
            url: `config/workflow/${this.type}/${this.rootObjectId}/coefficient/${coeff.coefficient.id}`,
            method: "PUT",
            data: {
              multiplier: coeff.coefficient.multiplier
            }
          });
        }
        for (const coeff of this.coefficients
          .skinTone as CoefficientCreationInterface[]) {
          await configuredAxios.request({
            url: `config/workflow/${this.type}/${this.rootObjectId}/coefficient/${coeff.coefficient.id}`,
            method: "PUT",
            data: {
              multiplier: coeff.coefficient.multiplier
            }
          });
        }
        for (const coeff of this.coefficients
          .faceShape as CoefficientCreationInterface[]) {
          await configuredAxios.request({
            url: `config/workflow/${this.type}/${this.rootObjectId}/coefficient/${coeff.coefficient.id}`,
            method: "PUT",
            data: {
              multiplier: coeff.coefficient.multiplier
            }
          });
        }
      } catch (error) {
        this.$store.dispatch("showAlert", {
          message: error.message,
          color: "warning",
          timeout: 4000
        });
      }
      this.loading = false;
      this.showDialog = false;
      this.$emit("loading", false);
      // TBD
    },
    refreshCoefficients() {
      this.editableCoefficients.eyeColor
        ? (this.coefficients.eyeColor = [...this.editableCoefficients.eyeColor])
        : [];
      this.editableCoefficients.hairColor
        ? (this.coefficients.hairColor = [
            ...this.editableCoefficients.hairColor
          ])
        : [];
      this.editableCoefficients.skinTone
        ? (this.coefficients.skinTone = [...this.editableCoefficients.skinTone])
        : [];
      this.editableCoefficients.faceShape
        ? (this.coefficients.faceShape = [
            ...this.editableCoefficients.faceShape
          ])
        : [];
    }
  },
  watch: {
    // Find something to watch to reset the thing (e.g : showDialog ?? that should be okay as we need to reset only if we cycle showDialog)
    async showDialog() {
      if (this.showDialog) {
        this.refreshCoefficients();
      }
    }
  }
});

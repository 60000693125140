import Vue from "vue";
import validator from "validator"

// We create a set of rules, that can take the Vue instance context to compare values if needed
export default (context?: Vue) => {
    return {
        textField(maxCharNumber: number) {
            return [
                (val: string) => (val && val.length > 1 || "La valeur renseignée est trop courte"),
                (val: string) => (val && val.length < maxCharNumber || "La valeur renseignée est trop longue"),
            ]
        },
        textFieldOptional(maxCharNumber: number) {
            return [
                (val: string) => (val !== null && val.length < maxCharNumber || "La valeur renseignée est trop longue"),
            ]
        },
        password: [
            (val: string) => (val.length > 7 || "Le mot de passe est trop court"),
            (val: string) => (/[A-Z]/.test(val) || "Le mot de passe doit contenir au moins une majuscule"),
            (val: string) => (/[a-z]/.test(val) || "Le mot de passe doit contenir au moins une minuscule"),
            (val: string) => (/[0-9]/.test(val) || "Le mot de passe doit contenir au moins un chiffre "),
        ],
        email: [
            (val: string) => (validator.isEmail(val) || "L'email semble invalide"),
        ],
        phone: [
            (val: string) => (validator.isMobilePhone(val) || "Veuillez entrer un numéro de mobile valide"),
        ],
        select: [
            (val: number) => (val > -1 || "Veuillez choisir un élement"),
        ]
    }
}




























































































import Vue from "vue";
import { mapState } from "vuex";

export default Vue.extend({
  computed: {
    ...mapState(["admin"])
  },
  data: () => ({
    menu: {
      top: [
        // { name: "Dashboard", icon: "mdi-view-dashboard", link: "/" },
        { name: "Admins", icon: "mdi-shield-account", link: "/admins" },
        { name: "Magasins", icon: "mdi-store", link: "/store" },
        {
          name: "Bilans",
          icon: "mdi-file-document-multiple",
          link: "/summaries"
        },
        { name: "Bilan Montures", icon: "mdi-glasses", link: "/frames" },
        { name: "Bilan Formes", icon: "mdi-shape", link: "/frameshapes" },
        { name: "Bilan Couleurs", icon: "mdi-palette", link: "/frametones" }
      ],
      bottom: [
        { name: "Marques", icon: "mdi-sunglasses", link: "/brands" },
        { name: "Couleurs", icon: "mdi-palette-swatch", link: "/colors" },
        { name: "Matières", icon: "mdi-recycle", link: "/matters" },
        { name: "Styles", icon: "mdi-palette-swatch-variant", link: "/styles" }
        //   { name: "Paramètres", icon: "mdi-cog", link: "/settings" },
      ],
      general: [
        { name: "Mentions légales", icon: "mdi-gavel", link: "/tos" },
      ],
      summary: [
        {
          name: "Formes de visage",
          icon: "mdi-face-man",
          link: "/faceshapes"
        },
        { name: "Tons de peau", icon: "mdi-palette", link: "/skintones" },
        { name: "Couleurs d'yeux", icon: "mdi-eye", link: "/eyecolors" },
        {
          name: "Couleurs de cheveux",
          icon: "mdi-face-woman-profile",
          link: "/haircolors"
        },
        { name: "Coefficients", icon: "mdi-cog", link: "/globalcoefficients" }
      ],
      superAdmin: [
        {
          name: "Import initial",
          icon: "mdi-file-delimited",
          link: "/firstimport"
        }
      ]
    }
  })
});

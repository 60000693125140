import configuredAxios from '@/modules/configured-axios';
import Vue from 'vue'
import Vuex from 'vuex'
/// <reference types="cordova-plugin-network-information" />

Vue.use(Vuex)


export default new Vuex.Store({
  state: {
    admin: {
      isLogged: false,
      token: "",
      uuid: "",
      superAdmin: false,
      expires: 0,
      userId: 0,
    },
    admins: [],
    stores: [],
    alert: {
      message: "",
      color: "",
      show: false,
    },
    api: configuredAxios
  },
  mutations: {
    ADMIN(state, payload) {
      state.admin = payload;
    },
    ALERT(state, payload) {
      state.alert = { ...payload };
    },
    ADMINS(state, payload) {
      state.admins = [...payload];
    },
    STORES(state, payload) {
      state.stores = [...payload];
    },
  },
  actions: {
    async getAdmins(context) {
      try {
        const admins = await configuredAxios.request({
          url: "admin",
          method: "GET"
        });
        context.commit("ADMINS", admins.data)
      } catch (error) {
        context.dispatch("showAlert", {
          message:
            "Impossible de récupérer les infos serveur pour le moment",
          color: "warning",
          timeout: 4000,
        });
      }
    },
    async getStores(context) {
      try {
        const stores = await configuredAxios.request({
          url: "store",
          method: "GET"
        });
        context.commit("STORES", stores.data)
      } catch (error) {
        context.dispatch("showAlert", {
          message:
            "Impossible de récupérer les infos serveur pour le moment",
          color: "warning",
          timeout: 4000,
        });
      }
    },
    showAlert(
      context,
      payload: { message: string; color: string; timeout: number }
    ) {
      context.commit("ALERT", {
        message: payload.message,
        color: payload.color,
        show: true,
      });
      setTimeout(() => {
        context.commit("ALERT", {
          message: payload.message,
          color: payload.color,
          show: false,
        });
        setTimeout(() => {
          context.commit("ALERT", { message: "", color: "", show: false });
        }, 500);
      }, payload.timeout);
    },
  },
  modules: {
  }
})


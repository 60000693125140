








































import configuredAxios from "@/modules/configured-axios";
import rules from "@/modules/rules";
import Vue from "vue";

export default Vue.extend({
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    showDialog: {
      get(): boolean {
        return this.show;
      },
      set(value: boolean) {
        if (!value) {
          this.email = "";
          this.password = "";
          (
            this.$refs.adminForm as HTMLFormElement & {
              resetValidation(): void;
            }
          ).resetValidation();
          this.$emit("close");
        }
      },
    },
  },
  data: () => ({
    email: "",
    password: "",
    username: "",
    formValid: false,
    loading: false,
    rules: rules(this),
  }),
  methods: {
    async addAdmin() {
      this.loading = true;
      try {
        await configuredAxios.request({
          url: "admin",
          method: "POST",
          data: {
            email: this.email,
            plainPassword: this.password,
          },
        });
        await this.$store.dispatch("getAdmins");
        this.showDialog = false;
      } catch (error) {
        console.error(error);
      }
      this.loading = false;
    },
  },
});





















































































import Vue from "vue";
import GalleryItem from "@/interfaces/GalleryItem";
import Media from "@/interfaces/db/MediaInterface";

export default Vue.extend({
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
  },
  computed: {
    showDialog: {
      get(): boolean {
        return this.show;
      },
      set(value: boolean) {
        if (!value) {
          this.gallery = [];
          this.galleryLoaded = false;
          this.galleryChanged = false;
          this.$emit("close");
        }
      },
    },
    filteredGallery: {
      get(): GalleryItem[] {
        return this.gallery.filter((value) => {
          return !value.markedForDeletion;
        });
      },
    },
  },
  data: () => ({
    saveLoading: false,
    gallery: [] as GalleryItem[],
    galleryPickingDialogFiles: [] as File[],
    galleryLoaded: true,
    galleryChanged: false,
  }),
  watch: {
    async show() {
      if (this.show && this.id) {
        // Fetch video object
        this.getGallery();
      }
    },
    galleryPickingDialogFiles() {
      // We need to process the files
      console.log("we've dropped some files");
      if (this.galleryLoaded) {
        if (this.galleryPickingDialogFiles.length) {
          // We validate each file
          for (const file of this.galleryPickingDialogFiles) {
            if (this.validateFile(file)) {
              // If file is valid, we create an entry in the array
              this.gallery.push({
                data: URL.createObjectURL(file),
                markedForDeletion: false,
                file,
              });
              this.galleryChanged = true;
            }
          }
          this.galleryPickingDialogFiles = [];
        }
      }
    },
  },
  methods: {
    async updateGallery() {
      try {
        this.saveLoading = true;
        const array = [] as File[];
        // We need to cycle through the gallery array
        for (const media of this.gallery as GalleryItem[]) {
          if (!media.markedForDeletion) {
            array.push(media.file);
          }
        }
        this.galleryChanged = false;
        this.saveLoading = false;
        this.$emit("changed", array);
      } catch (error) {
        console.error(error);
      }
    },
    handleDrop(e: DragEvent, fileKey: string) {
      if (e.dataTransfer?.files.length) {
        for (const file of e.dataTransfer.files) {
          if (this.validateFile(file)) {
            this.gallery.push({
              data: URL.createObjectURL(file),
              markedForDeletion: false,
              file,
            });
            this.galleryChanged = true;
          }
        }
      }
    },
    markForDeletion(media: GalleryItem) {
      media.markedForDeletion = true;
      this.galleryChanged = true;
    },
    validateFile(file: File) {
      if (file.type == "image/png" || file.type == "image/jpeg") {
        // We check the file size
        if (file.size < 1048000) {
          return true;
        } else {
          throw {
            message:
              "Le fichier est trop volumineux, sa taille doit être inférieure à 1Mo",
          };
        }
      } else {
        throw {
          message: "Veuillez utiliser un fichier .jpg ou .png",
        };
      }
    },
    selectImage(ref: string) {
      (
        this.$refs?.[ref as keyof (Vue | Element | (Vue | Element)[])] as any
      ).$refs.input.click();
    },
    async getGallery() {
      try {
        // We get all the gallery items for the coach
        this.galleryLoaded = false;
        this.gallery = [];
        for (const item of this.items) {
          this.gallery.push({
            data: URL.createObjectURL(item),
            markedForDeletion: false,
            item,
          });
        }
        this.galleryLoaded = true;
      } catch (error) {
        console.error(error);
      }
    },
  },
});

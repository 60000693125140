

































































import Vue from "vue";

export default Vue.extend({
  props: {
    parentVisible: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    valid() {
      return !!this.name && !!this.thumbnail;
    },
    thumbnailFile: {
      get(): File | null {
        return this.thumbnail as File;
      },
      set(value: File) {
        try {
          if (value) {
            if (this.validateThumbnail(value)) {
              this.thumbnail = value;
              this.thumbnailSrc = URL.createObjectURL(value);
              this.thumbnailChanged = true;
            } else {
              this.thumbnail = null;
              this.thumbnailSrc = require("@/assets/placeholder.png");
            }
          } else {
            this.thumbnail = value;
            this.thumbnailSrc = require("@/assets/placeholder.png");
            this.thumbnailChanged = true;
          }
        } catch (error: any) {
          this.$store.dispatch("showAlert", {
            message: error.message,
            color: "warning",
            timeout: 4000,
          });
        }
      },
    },
  },
  data: () => ({
    thumbnailSrc: require("@/assets/placeholder.png"),
    thumbnailChanged: false,
    thumbnail: null as File | null,
    name: "",
    description: "",
  }),
  methods: {
    selectImage() {
      (this.$refs.thumbnailSelector as any).$refs.input.click();
    },
    handleDrop(e: DragEvent) {
      if (e.dataTransfer!.files.length) {
        this.thumbnailFile = e.dataTransfer!.files[0];
      }
    },
    validateThumbnail(file: File) {
      if (file.type == "image/png" || file.type == "image/jpeg") {
        // We check the file size
        if (file.size < 1048000) {
          return true;
        } else {
          throw {
            message:
              "Le fichier est trop volumineux, sa taille doit être inférieure à 1Mo",
          };
        }
      } else {
        throw {
          message: "Veuillez utiliser un fichier .png ou jpg",
        };
      }
    },
    next() {
      // We create the object :
      const data = {
        name: this.name,
        description: this.description,
        thumbnail: this.thumbnail,
      };
      this.$emit("next", data);
    },
  },
  watch: {
    // Find something to watch to reset the thing (e.g : showDialog ?? that should be okay as we need to reset only if we cycle showDialog)
    parentVisible() {
      if (!this.parentVisible) {
        this.name = "";
        this.description = ""
        this.thumbnailFile = null;
        this.thumbnailChanged = false;
      }
    },
  },
});

import { ConfigRef } from "./reference"

type RecursiveKeyOf<TObj extends Record<string, unknown>> = {
    [TKey in keyof TObj & string]: TObj[TKey] extends Record<string, unknown> ? `${TKey}.${RecursiveKeyOf<TObj[TKey]>}` : `${TKey}`;
}[keyof TObj & string];

class ConfigManager {

    getVersion() {
        return this.internalGetConfig('VERSION');
    }

    getConfig(key: RecursiveKeyOf<ConfigRef>, optional = true): string | undefined {

        return this.internalGetConfig(key, optional);
    }

    getConfigAsString(key: RecursiveKeyOf<ConfigRef>, optional = true): string | undefined {
        const conf = this.getConfig(key, optional);

        if (conf == null) return undefined;

        return conf.toString();
    }

    getConfigAsBoolean(key: RecursiveKeyOf<ConfigRef>, optional = true): boolean | undefined | undefined {
        const conf = this.getConfig(key, optional);

        if (conf == null) return undefined;

        if (!!conf === true || conf === '1' || conf.toLowerCase() === 'true')
            return true;

        if (!!conf === false || conf === '' || conf === '0' || conf.toLowerCase() === 'false')
            return false;

        return undefined;
    }

    getConfigAsNumber(key: RecursiveKeyOf<ConfigRef>, optional = true): number | undefined {
        const conf = this.getConfig(key, optional);

        if (conf == null) return undefined;

        return Number(conf);
    }

    private internalGetConfig(key: string, optional = false): string | undefined {

        key = `VUE_APP_${key.toUpperCase().replace(/[.]/gi, '_')}`;

        if (typeof process.env[key] === undefined) {

            if (optional)
                return undefined;

            throw new Error(`Config key ${key} was not found`);
        }


        return process.env[key];
    }
}

export default new ConfigManager();


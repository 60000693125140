

































































import CoefficientInterface from "@/interfaces/db/CoefficientInterface";
import configuredAxios from "@/modules/configured-axios";
import Vue from "vue";
import { mapActions } from "vuex";
import Step1Vue from "./add-frame-tone/Step1.vue";
import Step2Vue from "./add-frame-tone/Step2.vue";
import Step3Vue from "./add-frame-tone/Step3.vue";
import Step4Vue from "./add-frame-tone/Step4.vue";
import Step5Vue from "./add-frame-tone/Step5.vue";

export default Vue.extend({
  components: {
    Step1Vue,
    Step2Vue,
    Step3Vue,
    Step4Vue,
    Step5Vue,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    showDialog: {
      get(): boolean {
        return this.show;
      },
      set(value: boolean) {
        if (!value) {
          this.$emit("close");
          this.object.name = "";
          this.object.thumbnail = null;
          this.object.coefficients = [];
          setTimeout(() => {
            // TBD
            this.step = 1;
          }, 300);
        }
      },
    },
  },
  data() {
    return {
      createLoading: false,
      step: 1,
      object: {
        name: "",
        description: "",
        thumbnail: null as null | File,
        coefficients: [] as CoefficientInterface[],
      },
    };
  },
  methods: {
    ...mapActions(["showAlert"]),
    async createObject() {
      try {
        this.createLoading = true;
        const optionalObject = {} as Record<string, any>;
        if (this.showDescription) {
          optionalObject.description = this.description;
        }
        if (this.showRef) {
          optionalObject.ref = this.ref;
        }
        // Creating the object
        const object = (
          await configuredAxios.request({
            method: "POST",
            url: `config/${this.url}`,
            data: {
              name: this.name,
              ...optionalObject,
            },
          })
        ).data;

        if (this.media) {
          // The user has set a new thumbnail, we need to create the media and assign it to the video
          // We create the formdata
          const formData = new FormData();
          formData.append("media", this.thumbnailFile);

          const media = (
            await configuredAxios.request({
              url: `media`,
              data: formData,
              method: "POST",
            })
          ).data;
          // Once we got the media, we can assign the thumbnail to the video

          const assignedThumbnail = await configuredAxios.request({
            url: `config/${this.url}/${object.id}/media/${media.id}`,
            method: "PUT",
          });
        }

        this.createLoading = false;
        this.$store.dispatch("showAlert", {
          message: "L'objet a été créé",
          color: "success",
          timeout: 4000,
        });
        this.$emit("updated");
        this.name = "";
        this.ref = "";
        this.description = "";
        this.thumbnailChanged = false;
        this.showDialog = false;
      } catch (error) {
        this.$store.dispatch("showAlert", {
          message: error.response ? error.response.data.message : error.message,
          color: "warning",
          timeout: 3500,
        });
        console.error(error);
        this.$emit("updated");
        this.showDialog = false;
      }
    },
    completeStep1(data: {
      name: string;
      thumbnail: File;
      description: string;
    }) {
      this.object.name = data.name;
      this.object.description = data.description;
      this.object.thumbnail = data.thumbnail;
      this.step = 2;
    },
    completeStep2(data: CoefficientInterface[]) {
      // We need to delete all the coefficients that have an eyecolor_id before updating them
      this.object.coefficients = (
        this.object.coefficients as CoefficientInterface[]
      ).filter((coeff) => {
        return !coeff.eyecolor_id;
      });
      for (const coeff of data) {
        this.object.coefficients.push(coeff);
      }
      this.step = 3;
    },
    completeStep3(data: CoefficientInterface[]) {
      // We need to delete all the coefficients that have an eyecolor_id before updating them
      this.object.coefficients = (
        this.object.coefficients as CoefficientInterface[]
      ).filter((coeff) => {
        return !coeff.haircolor_id;
      });
      for (const coeff of data) {
        this.object.coefficients.push(coeff);
      }
      this.step = 4;
    },
    completeStep4(data: CoefficientInterface[]) {
      // We need to delete all the coefficients that have an eyecolor_id before updating them
      this.object.coefficients = (
        this.object.coefficients as CoefficientInterface[]
      ).filter((coeff) => {
        return !coeff.skintone_id;
      });
      for (const coeff of data) {
        this.object.coefficients.push(coeff);
      }
      this.step = 5;
    },
    async completeStep5() {
      // TBD
      this.createLoading = true;
      try {
        // Creating the object
        const object = (
          await configuredAxios.request({
            method: "POST",
            url: `config/workflow/frametone`,
            data: {
              name: this.object.name,
              description: this.object.description,
            },
          })
        ).data;
        // The user has set a new thumbnail, we need to create the media and assign it to the video
        // We create the formdata
        const formData = new FormData();
        formData.append("media", this.object.thumbnail);
        const media = (
          await configuredAxios.request({
            url: `media`,
            data: formData,
            method: "POST",
          })
        ).data;
        // Once we got the media, we can assign the thumbnail to the video
        const assignedThumbnail = await configuredAxios.request({
          url: `config/workflow/frametone/${object.id}/media/${media.id}`,
          method: "PUT",
        });
        // Now the fun part, we can create every coefficients we previously set :
        for (const coefficient of this.object.coefficients) {
          await configuredAxios.request({
            url: `config/workflow/frametone/${object.id}/coefficient`,
            method: "POST",
            data: coefficient,
          });
        }
      } catch (error) {
        this.showAlert({
          message: error.response ? error.response.data.message : error.message,
          color: "warning",
          timeout: 3500,
        });
        console.error(error);
      }
      this.createLoading = false;
      this.showDialog = false;
    },
    cancelEverything() {
      // TBD
      this.showDialog = false;
    },
    previousPage() {
      this.step--;
    },
  },
});













































































































import api from "@/modules/configured-axios";
import Vue from "vue";
import GlobalCoefficientInterface from "@/interfaces/db/GlobalCoefficientInterface";
import { TargetType } from "@/interfaces/TargetType";
import { ChildCoefficientType } from "@/interfaces/ChildCoefficientType";
import { mapActions } from "vuex";

const defaultCoefficients: GlobalCoefficientInterface[] = [
  {
    targetType: TargetType.FRAME,
    childCoefficientType: ChildCoefficientType.FACESHAPE,
    multiplier: 5,
  },
  {
    targetType: TargetType.FRAME,
    childCoefficientType: ChildCoefficientType.SKINTONE,
    multiplier: 5,
  },
  {
    targetType: TargetType.FRAME,
    childCoefficientType: ChildCoefficientType.EYECOLOR,
    multiplier: 5,
  },
  {
    targetType: TargetType.FRAME,
    childCoefficientType: ChildCoefficientType.HAIRCOLOR,
    multiplier: 5,
  },
  {
    targetType: TargetType.FRAMETONE,
    childCoefficientType: ChildCoefficientType.SKINTONE,
    multiplier: 5,
  },
  {
    targetType: TargetType.FRAMETONE,
    childCoefficientType: ChildCoefficientType.HAIRCOLOR,
    multiplier: 5,
  },
  {
    targetType: TargetType.FRAMETONE,
    childCoefficientType: ChildCoefficientType.EYECOLOR,
    multiplier: 5,
  },
];

export default Vue.extend({
  name: "Home",

  components: {
    // Coefficients : TargetType Frame  : FaceShape, SkinTone, EyeColor, HairColor
    // FrameTone : HairColor, EyeColor, SkinColor
    // First : Get them, if they exists, find and replace, if not, create when updated (boolean exists)
  },
  data: () => {
    return {
      updateDisabled: true,
      updateLoading: false,
      loaded: false,
      frameCoefficients: {
        hairColor: null as null | GlobalCoefficientInterface,
        eyeColor: null as null | GlobalCoefficientInterface,
        faceShape: null as null | GlobalCoefficientInterface,
        skinTone: null as null | GlobalCoefficientInterface,
      },
      frameToneCoefficients: {
        hairColor: null as null | GlobalCoefficientInterface,
        eyeColor: null as null | GlobalCoefficientInterface,
        skinTone: null as null | GlobalCoefficientInterface,
      },
    };
  },
  async beforeMount() {
    let coefficients = (
      await api.request({
        url: "global",
        method: "GET",
      })
    ).data as GlobalCoefficientInterface[];
    if (coefficients.length == 0) {
      await this.createFirstCoefficients();
      coefficients = (
        await api.request({
          url: "global",
          method: "GET",
        })
      ).data;
    }
    const frameTargetCoefficients = coefficients.filter((value) => {
      return value.targetType == TargetType.FRAME;
    });
    const frameToneTargetCoefficients = coefficients.filter((value) => {
      return value.targetType == TargetType.FRAMETONE;
    });
    // Now we assign each coefficient to its value :
    this.frameCoefficients.hairColor = frameTargetCoefficients.find((value) => {
      return value.childCoefficientType == ChildCoefficientType.HAIRCOLOR;
    });
    this.frameCoefficients.eyeColor = frameTargetCoefficients.find((value) => {
      return value.childCoefficientType == ChildCoefficientType.EYECOLOR;
    });
    this.frameCoefficients.faceShape = frameTargetCoefficients.find((value) => {
      return value.childCoefficientType == ChildCoefficientType.FACESHAPE;
    });
    this.frameCoefficients.skinTone = frameTargetCoefficients.find((value) => {
      return value.childCoefficientType == ChildCoefficientType.SKINTONE;
    });
    this.frameToneCoefficients.hairColor = frameToneTargetCoefficients.find(
      (value) => {
        return value.childCoefficientType == ChildCoefficientType.HAIRCOLOR;
      }
    );
    this.frameToneCoefficients.eyeColor = frameToneTargetCoefficients.find(
      (value) => {
        return value.childCoefficientType == ChildCoefficientType.EYECOLOR;
      }
    );
    this.frameToneCoefficients.skinTone = frameToneTargetCoefficients.find(
      (value) => {
        return value.childCoefficientType == ChildCoefficientType.SKINTONE;
      }
    );
    this.loaded = true;
  },
  methods: {
    async createFirstCoefficients() {
      for (const coefficient of defaultCoefficients) {
        await api.request({
          url: "global",
          method: "POST",
          data: coefficient,
        });
      }
    },
    async updateCoefficients() {
      // TBD
      this.updateLoading = true;
      try {
        for (const key in this.frameCoefficients) {
          const coeff = this.frameCoefficients[
            key
          ] as GlobalCoefficientInterface;
          await api.request({
            url: `global/${coeff.id}`,
            method: "PUT",
            data: {
              multiplier: coeff.multiplier,
            },
          });
        }
        for (const key in this.frameToneCoefficients) {
          const coeff = this.frameToneCoefficients[
            key
          ] as GlobalCoefficientInterface;
          await api.request({
            url: `global/${coeff.id}`,
            method: "PUT",
            data: {
              multiplier: coeff.multiplier,
            },
          });
        }
        this.showAlert({
          message: "Les paramètres ont été mis à jour",
          color: "success",
          timeout: 4000,
        });
      } catch (error) {
        console.error(error);
        this.showAlert({
          message: error.message,
          color: "warning",
          timeout: 4000,
        });
      }
      this.updateDisabled = true;
      this.updateLoading = false;
    },
    ...mapActions(["showAlert"]),
  },
});

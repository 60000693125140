



















































































import Vue from "vue";
import FaceShape from "@/interfaces/db/FaceShapeInterface";
import { mapActions } from "vuex";
import configuredAxios from "@/modules/configured-axios";
import configManager from "@/modules/config-manager";
import AddObjectDialogVue from "@/components/AddObjectDialog.vue";

export default Vue.extend({
  components: {
    AddObjectDialogVue,
  },
  data: () => {
    return {
      showAddDialog: false,
      showDeleteDialog: false,
      editIndex: 0,
      deleteIndex: 0,
      deleteLoading: false,
      faceShapes: [] as FaceShape[],
    };
  },
  methods: {
    ...mapActions["showAlert"],
    getMediaRoute(filename?: string) {
      if (filename) {
        return `${configManager.getConfigAsString(
          "axios.apiHost"
        )}/images/${filename}`;
      } else {
        return require("@/assets/placeholder.png");
      }
    },
    prepareObjectDeletion(id: number) {
      this.deleteIndex = id;
      this.showDeleteDialog = true;
    },
    async deleteObject(id: number) {
      this.deleteLoading = true;
      try {
        await configuredAxios.request({
          url: `config/faceshape/${this.deleteIndex}`,
          method: "DELETE",
        });
        await this.refreshObjects();
      } catch (error) {
        this.showAlert({
          message: error.message,
          color: "warning",
          timeout: 3500,
        });
        console.error(error);
      }
      this.deleteIndex = 0;
      this.deleteLoading = false;
      this.showDeleteDialog = false;
    },
    async refreshObjects() {
      // TBD
      try {
        // TBD
        const faceShapes = (
          await configuredAxios.request({
            url: "/config/faceshape",
            method: "GET",
          })
        ).data;
        this.faceShapes = faceShapes;
      } catch (error) {
        this.showAlert({
          message: error.response ? error.response.data.message : error.message,
          color: "warning",
          timeout: 3500,
        });
        console.error(error);
      }
    },
    async afterCreatedObject() {
      this.showAddDialog = false;
      await this.refreshObjects();
    },
  },

  async beforeMount() {
    await this.refreshObjects();
  },
});

































































































































































































































































import CoefficientCreationInterface from "@/interfaces/CoefficientCreationInterface";
import BrandInterface from "@/interfaces/db/BrandInterface";
import CoefficientInterface from "@/interfaces/db/CoefficientInterface";
import ColorInterface from "@/interfaces/db/ColorInterface";
import EyeColorInterface from "@/interfaces/db/EyeColorInterface";
import FaceShapeInterface from "@/interfaces/db/FaceShapeInterface";
import FrameInterface from "@/interfaces/db/FrameInterface";
import FrameToneInterface from "@/interfaces/db/FrameToneInterface";
import HairColorInterface from "@/interfaces/db/HairColorInterface";
import MatterInterface from "@/interfaces/db/MatterInterface";
import SkinToneInterface from "@/interfaces/db/SkinToneInterface";
import StyleInterface from "@/interfaces/db/StyleInterface";
import { Gender } from "@/interfaces/Gender";
import configManager from "@/modules/config-manager";
import configuredAxios from "@/modules/configured-axios";
import rules from "@/modules/rules";
import Vue from "vue";
import EditCoefficientsDialogVue from "./EditCoefficientsDialog.vue";
import MediaGalleryDialogVue from "./MediaGalleryDialog.vue";

export default Vue.extend({
  components: {
    EditCoefficientsDialogVue,
    MediaGalleryDialogVue
  },
  props: {
    id: {
      type: Number,
      required: true
    },
    show: {
      type: Boolean,
      required: true
    },
    url: {
      type: String,
      required: true
    }
  },
  computed: {
    numberOfMissingCoefficients() {
      if (this.object) {
        return this.totalCoefficientNumber - this.object.Coefficients.length;
      } else {
        return 0;
      }
    },
    showDialog: {
      get(): boolean {
        return this.show;
      },
      set(value: boolean) {
        if (!value) {
          this.object = null;
          this.objectLoaded = false;
          this.objectEdited = false;
          this.totalCoefficientNumber = 0;
          this.editableCoefficients = {
            eyeColor: [],
            hairColor: [],
            skinTone: []
            // faceShape: [] as CoefficientCreationInterface[]
          };
          (this.$refs.editObjectForm as any).resetValidation();
          this.$emit("close");
          setTimeout(() => {
            this.objectLoading = true;
          }, 300);
        }
      }
    }
  },
  data() {
    return {
      genders: [
        {
          text: "Homme",
          value: Gender.MALE
        },
        {
          text: "Femme",
          value: Gender.FEMALE
        },
        {
          text: "Mixte",
          value: Gender.OTHER
        }
      ],
      showCoefficientDialog: false,
      showGalleryDialog: false,
      totalCoefficientNumber: 0,
      editableCoefficients: {
        eyeColor: [] as CoefficientCreationInterface[],
        hairColor: [] as CoefficientCreationInterface[],
        skinTone: [] as CoefficientCreationInterface[],
        faceShape: [] as CoefficientCreationInterface[]
      },
      brands: [] as BrandInterface[],
      colors: [] as ColorInterface[],
      matters: [] as MatterInterface[],
      styles: [] as StyleInterface[],
      selectedBrand: 0,
      selectedColor: 0,
      selectedMatter: 0,
      selectedStyle: 0,
      objectLoading: true,
      deleteDialog: false,
      deleteLoading: false,
      updateLoading: false,
      object: null as null | FrameToneInterface,
      objectLoaded: false,
      objectEdited: false,
      rules: rules(this),
      valid: false,
      editLoading: false
    };
  },
  watch: {
    async id() {
      // If ID, then we fetch the details
      if (this.id) {
        // Get total coefficients number we should have configured for this kind of workflow
        this.totalCoefficientNumber = await this.getTotalCoefficientNumber();
        // Fetch object
        await this.getObject();
        this.editLoading = true;
        // Now, we can create the coefficient list that can be edited
        await this.createEditableCoefficients();
        this.editLoading = false;
        const brands = (
          await configuredAxios.request({
            url: "/config/brand",
            method: "GET"
          })
        ).data;
        this.brands = brands;
        const colors = (
          await configuredAxios.request({
            url: "/config/color",
            method: "GET"
          })
        ).data;
        this.colors = colors;
        const matters = (
          await configuredAxios.request({
            url: "/config/matter",
            method: "GET"
          })
        ).data;
        this.matters = matters;
        const styles = (
          await configuredAxios.request({
            url: "/config/style",
            method: "GET"
          })
        ).data;
        this.styles = styles;
      }
    },
    object: {
      deep: true,
      handler(newValue, oldValue) {
        // If the video changes while being "loaded", it means that the admin has edited it
        // we thus need to set it as "edited to allow the button to be clickable
        if (oldValue && this.objectLoaded) {
          this.objectEdited = true;
        }
      }
    }
  },
  methods: {
    setLoading(e: boolean) {
      console.log(e);
    },
    async getTotalCoefficientNumber() {
      // (number of EyeColors + number of HairColor + number of skinTones)
      const eyeColors = (
        await configuredAxios.request({
          url: "/config/eyecolor",
          method: "GET"
        })
      ).data as EyeColorInterface[];
      const hairColors = (
        await configuredAxios.request({
          url: "/config/haircolor",
          method: "GET"
        })
      ).data as HairColorInterface[];
      const skinTones = (
        await configuredAxios.request({
          url: "/config/skintone",
          method: "GET"
        })
      ).data as SkinToneInterface[];
      const faceShapes = (
        await configuredAxios.request({
          url: "/config/faceshape",
          method: "GET"
        })
      ).data as FaceShapeInterface[];

      return (
        eyeColors.length +
        hairColors.length +
        skinTones.length +
        faceShapes.length
      );
    },
    async deleteObject(id: number) {
      this.deleteLoading = true;
      await configuredAxios.request({
        url: `config/workflow/${this.url}/${id}`,
        method: "DELETE"
      });
      this.deleteLoading = false;
      this.showDialog = false;
    },
    async updateObject() {
      try {
        this.updateLoading = true;
        if (this.object) {
          // GALLERY LOGIC
          await configuredAxios.request({
            url: `config/workflow/${this.url}/${this.object.id}`,
            method: "PUT",
            data: {
              name: this.object.name,
              sku: this.object.sku,
              sizeTemple: this.object.sizeTemple,
              sizeBridge: this.object.sizeBridge,
              sizeLens: this.object.sizeLens,
              description: this.object.description,
              gender: this.object.gender
              // OTHER PROPERTIES
            } as FrameInterface
          });
          // ASSOCIATIONS UPDATES
          if (this.selectedBrand > 0) {
            await configuredAxios.request({
              url: `config/workflow/frame/${this.object.id}/brand/${this.selectedBrand}`,
              method: "PUT"
            });
          }

          if (this.selectedColor > 0) {
            await configuredAxios.request({
              url: `config/workflow/frame/${this.object.id}/color/${this.selectedColor}`,
              method: "PUT"
            });
          }
          if (this.selectedMatter > 0) {
            await configuredAxios.request({
              url: `config/workflow/frame/${this.object.id}/matter/${this.selectedMatter}`,
              method: "PUT"
            });
          }
          if (this.selectedStyle > 0) {
            await configuredAxios.request({
              url: `config/workflow/frame/${this.object.id}/style/${this.selectedStyle}`,
              method: "PUT"
            });
          }
        }
        this.updateLoading = false;
        this.$store.dispatch("showAlert", {
          message: "L'objet a été mis à jour",
          color: "success",
          timeout: 4000
        });
        this.$emit("updated");
        this.objectEdited = false;
      } catch (error) {
        console.error(error);
      }
    },
    changeRelatedSetting() {
      if (this.objectLoaded) {
        this.objectEdited = true;
      }
    },
    async getObject() {
      try {
        this.objectLoaded = false;
        const request = await configuredAxios.request({
          url: `config/workflow/${this.url}/${this.id}`,
          method: "GET"
        });
        const object = request.data as FrameInterface;
        this.object = object;

        // If we're here, we can already show the video information
        this.objectLoading = false;

        // We update the selectedThings
        this.selectedBrand = object.Brand ? object.Brand.id : 0;
        this.selectedColor = object.Color ? object.Color.id : 0;
        this.selectedMatter = object.Matter ? object.Matter.id : 0;
        this.selectedStyle = object.Style ? object.Style.id : 0;
        // GALLERY HANDLING SOMEWHERE
        this.objectLoaded = true;
        this.objectEdited = false;
      } catch (error) {
        // Show alert and close
        this.$store.dispatch("showAlert", {
          message: error.message,
          color: "warning",
          timeout: 4000
        });
      }
    },
    async createEditableCoefficients() {
      this.editableCoefficients = {
        eyeColor: [] as CoefficientCreationInterface[],
        hairColor: [] as CoefficientCreationInterface[],
        skinTone: [] as CoefficientCreationInterface[],
        faceShape: [] as CoefficientCreationInterface[]
      };
      // First, we need to sort the coefficients by type, then get the object corresponding and create the object
      // associated with it (name and coefficient wise)
      const eyeColorCoefficients = (this.object
        .Coefficients as CoefficientInterface[]).filter(coeff => {
        return coeff.eyecolor_id;
      });
      const hairColorCoefficients = (this.object
        .Coefficients as CoefficientInterface[]).filter(coeff => {
        return coeff.haircolor_id;
      });
      const skinToneCoefficients = (this.object
        .Coefficients as CoefficientInterface[]).filter(coeff => {
        return coeff.skintone_id;
      });
      const faceShapeCoefficients = (this.object
        .Coefficients as CoefficientInterface[]).filter(coeff => {
        return coeff.faceshape_id;
      });

      // Then, we get the related names and push everything
      for (const coeff of faceShapeCoefficients as CoefficientInterface[]) {
        const relatedObject = (
          await configuredAxios.request({
            url: `config/faceshape/${coeff.faceshape_id}`
          })
        ).data as FaceShapeInterface;
        (this.editableCoefficients
          .faceShape as CoefficientCreationInterface[]).push({
          name: relatedObject.name,
          coefficient: coeff
        });
      }
      for (const coeff of eyeColorCoefficients as CoefficientInterface[]) {
        const relatedObject = (
          await configuredAxios.request({
            url: `config/eyecolor/${coeff.eyecolor_id}`
          })
        ).data as EyeColorInterface;
        (this.editableCoefficients
          .eyeColor as CoefficientCreationInterface[]).push({
          name: relatedObject.name,
          coefficient: coeff
        });
      }
      for (const coeff of hairColorCoefficients as CoefficientInterface[]) {
        const relatedObject = (
          await configuredAxios.request({
            url: `config/haircolor/${coeff.haircolor_id}`
          })
        ).data as HairColorInterface;
        (this.editableCoefficients
          .hairColor as CoefficientCreationInterface[]).push({
          name: relatedObject.name,
          coefficient: coeff
        });
      }
      for (const coeff of skinToneCoefficients as CoefficientInterface[]) {
        const relatedObject = (
          await configuredAxios.request({
            url: `config/skintone/${coeff.skintone_id}`
          })
        ).data as SkinToneInterface;
        (this.editableCoefficients
          .skinTone as CoefficientCreationInterface[]).push({
          name: relatedObject.name,
          coefficient: coeff
        });
      }
    },
    async createMissingCoefficients() {
      // We create an empty array
      const missingCoefficients = [] as CoefficientInterface[];

      // We need to get all the objects we need to determine the missing coefficients
      const eyeColors = (
        await configuredAxios.request({
          url: "/config/eyecolor",
          method: "GET"
        })
      ).data as EyeColorInterface[];
      const hairColors = (
        await configuredAxios.request({
          url: "/config/haircolor",
          method: "GET"
        })
      ).data as HairColorInterface[];
      const skinTones = (
        await configuredAxios.request({
          url: "/config/skintone",
          method: "GET"
        })
      ).data as SkinToneInterface[];
      const faceShapes = (
        await configuredAxios.request({
          url: "/config/faceshape",
          method: "GET"
        })
      ).data as EyeColorInterface[];

      // Then, for each of the object we search the related coefficient inside the existing object
      for (const object of eyeColors) {
        const coefficientFound = (this.object
          .Coefficients as CoefficientInterface[]).find(coeff => {
          return coeff.eyecolor_id == object.id;
        });
        // if we haven't found it, we need to buffer it inside the appropriate array
        if (!coefficientFound) {
          missingCoefficients.push({
            eyecolor_id: object.id,
            multiplier: 5
          });
        }
      }
      for (const object of hairColors) {
        const coefficientFound = (this.object
          .Coefficients as CoefficientInterface[]).find(coeff => {
          return coeff.haircolor_id == object.id;
        });
        // if we haven't found it, we need to buffer it inside the appropriate array
        if (!coefficientFound) {
          missingCoefficients.push({
            haircolor_id: object.id,
            multiplier: 5
          });
        }
      }
      for (const object of skinTones) {
        const coefficientFound = (this.object
          .Coefficients as CoefficientInterface[]).find(coeff => {
          return coeff.skintone_id == object.id;
        });
        // if we haven't found it, we need to buffer it inside the appropriate array
        if (!coefficientFound) {
          missingCoefficients.push({
            skintone_id: object.id,
            multiplier: 5
          });
        }
      }
      for (const object of faceShapes) {
        const coefficientFound = (this.object
          .Coefficients as CoefficientInterface[]).find(coeff => {
          return coeff.faceshape_id == object.id;
        });
        // if we haven't found it, we need to buffer it inside the appropriate array
        if (!coefficientFound) {
          missingCoefficients.push({
            faceshape_id: object.id,
            multiplier: 5
          });
        }
      }

      // Now, we create them
      for (const coefficient of missingCoefficients) {
        await configuredAxios.request({
          url: `config/workflow/frame/${this.object.id}/coefficient`,
          method: "POST",
          data: coefficient
        });
      }
      // Once it's created, we just need to go through the object refresh and calculations once more
      await this.getObject();
      // Now, we can create the coefficient list that can be edited
      await this.createEditableCoefficients();
    },
    async afterCloseDialog() {
      this.showCoefficientDialog = false;
      this.editLoading = true;
      await this.getObject();
      await this.createEditableCoefficients();
      this.editLoading = false;
    },
    afterCloseGallery() {
      this.showGalleryDialog = false;
    }
  }
});
